{
  "meta": {
    "title": "万代島多目的広場 大かま",
    "catchphrase": "Moving Hearts Minato Oasis Niigata",
    "description": "新潟市民の皆様がいつでも気軽に足を運んでいただけるような楽しいにぎわいをご用意してお待ちしております。あなたの心を打つ…新潟のオアシスになるように…",
    "twitter_name": "@bandaijima4_2",
    "url": "https://niigata-ookama.com",
    "og_image": "https://niigata-ookama.com/images/og_image.jpg"
  },
  "header": {
    "menus": [
      {
        "name": "ご利用について",
        "url": "/guide"
      },
      {
        "name": "トピックス",
        "url": "/topics"
      },
      {
        "name": "イベントスケジュール",
        "url": "/events"
      },
      {
        "name": "専用利用時の使用料金",
        "url": "/usage"
      },
      {
        "name": "専用利用の仮申込",
        "url": "/contact/reservation"
      },
      {
        "name": "お問い合わせ",
        "url": "/contact/inquiry"
      }
    ]
  },
  "footer": {
    "menus": [
      {
        "name": "「知る」",
        "items": [
          {
            "name": "ホーム",
            "url": "/"
          },
          {
            "name": "トピックス",
            "url": "/topics"
          },
          {
            "name": "イベントスケジュール",
            "url": "/events"
          },
          {
            "name": "お問い合わせ",
            "url": "/contact/inquiry"
          }
        ]
      },
      {
        "name": "「利用する」",
        "items": [
          {
            "name": "ご利用について",
            "url": "/guide"
          },
          {
            "name": "専用利用時の使用料金",
            "url": "/usage"
          },
          {
            "name": "専用利用の仮申込",
            "url": "/contact/reservation"
          },
          {
            "name": "会場の予約状況",
            "url": "/vacancies"
          }
        ]
      },
      {
        "name": "「SNS」",
        "items": [
          {
            "name": "X（エックス）",
            "url": "https://twitter.com/bandaijima4_2",
            "external": true
          },
          {
            "name": "Instagram",
            "url": "https://www.instagram.com/bandaijima_hiroba?hl=ja",
            "external": true
          },
          {
            "name": "アカウント運用ポリシー",
            "url": "/policies/social-media"
          }
        ]
      }
    ],
    "banners": [
      {
        "name": "万代島多目的広場 新潟市中央区",
        "image": "/images/banners/niigata.jpg",
        "url": "https://www.city.niigata.lg.jp/chuo/shisetsu/yoka/bunka/bandaijima_hiroba.html"
      },
      {
        "name": "BSNウェーブ",
        "image": "/images/banners/bsnwave.jpg",
        "url": "http://www.bsnwave.com"
      },
      {
        "name": "NSI",
        "image": "/images/banners/nsi.jpg",
        "url": "https://nsi.jp"
      }
    ]
  },

  "website": {
    "info": {
      "charge": {
        "name": "万代島多目的広場事務室"
      },
      "manager": {
        "name": "万代島多目的広場にぎわい創出委員会",
        "tel": "025-278-8296",
        "fax": "025-278-8297",
        "postal_code": "950-0078",
        "address": "新潟市中央区万代島4番地2"
      },
      "installer": {
        "name": "新潟市都市政策部港湾空港課",
        "tel": "025-226-2739"
      }
    }
  }
}
